import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form as FinalForm} from 'react-final-form';
import {Form, Grid, Checkbox} from 'semantic-ui-react';
import {
  InternalError,
  PageNotFound,
  CollapseBox,
  DropdownField,
  InputField,
  toast,
  intlShape,
} from '@ecosio/components';
import {styled} from 'styled-components';
import axios from 'axios';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage as Msg} from 'react-intl';
import {Helmet} from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import htmlParse from 'html-react-parser';
import {get} from 'lodash';
import SubmitButton from '../SubmitButton';
import {historyShape} from '../../../../shapes';
import {processValidationResult, required} from '../../../helpers/validators';
import {FormFieldsWrapper} from '../FormFieldsWrapper';
import {SettingsFormWrapper} from '../SettingsStyles';
import {CompanyTypes} from '../../../constants';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {MainCategory, trackEvent} from '../../../analytics';
import {ErrorLabel} from '@ecosio/pathform';

const Logo = styled.div`
  height: 81px;
`;

const TermsOfServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckBoxContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  ${(props) =>
    props?.disabled
      ? `
  pointer-events: none;
  opactiy: 0.4;
  color: grey;
  `
      : ``}
`;

const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const localeOptions = ['de', 'en'].map((status) => ({
  text: status,
  value: status,
  key: status,
}));

const mapStateToProps = (state) => ({
  userId: state.config?.userConfig?.email,
  userConfig: state.config?.userConfig,
});

class AccountsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {disableButton: false, errorCode: false};
  }

  async componentDidMount() {
    this.fetchUser();
    this.fetchTimeZones();
  }

  async fetchUser() {
    try {
      const response = await axios.get(`/api/user/profile`);

      this.setState({userDetails: response?.data});
    } catch (error) {
      this.setState({errorCode: error.response.status});
      console.error(error);
    }
  }

  async fetchTimeZones() {
    try {
      const response = await axios.get(`/api/user/time-zones`);
      const timeZones = Object.values(response.data).map((region) => ({
        text: region,
        value: region,
        key: region,
      }));

      this.setState({timeZones: timeZones});
    } catch (error) {
      console.error(error);
    }
  }

  handleNetworkError = (error, intl) => {
    if (error.response.status === 409) {
      toast({
        title: intl.formatMessage({id: 'SETTINGS_CONCURRENT_UPDATE_TITLE'}),
        description: intl.formatMessage({
          id: 'SETTINGS_CONCURRENT_UPDATE_DESCRIPTION',
        }),
        type: 'warning',
        time: 100 * 1000,
      });
    } else {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
        time: 100 * 1000,
      });
    }
  };

  handleResult = (res, intl) => {
    if (res.status === 200) {
      toast({
        title: intl.formatMessage({id: 'USER_UPDATE_SUCCESS_TITLE'}),
        description: intl.formatMessage({
          id: 'USER_UPDATE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
      this.fetchUser();
    }
  };

  onSubmit = async (values) => {
    const {intl} = this.props;
    this.setState({disableButton: true});

    const data = cloneDeep(values);
    //removing userId while posting, the userId is from redux store
    delete data['userId'];

    return await axios
      .post(`/api/user`, data)
      .then((res) => {
        this.setState({disableButton: false});
        this.handleResult(res, intl);
        trackEvent('Save company profile', {
          mainCategory: MainCategory.ButtonClick,
          companyUuid: this.state.userConfig?.company?.uuid,
          companyName: this.state.userConfig?.company?.name,
        });
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          this.setState({disableButton: false});
          return processValidationResult(e.response?.data, intl);
        } else {
          this.setState({disableButton: false});
          this.handleNetworkError(e, intl);
        }
      });
  };
  render() {
    const {history, intl, userConfig} = this.props;
    const {disableButton, userDetails, errorCode} = this.state;

    const initialValues = userDetails;
    if (initialValues && !initialValues['userId']) {
      initialValues['userId'] = this.props?.userId;
    }
    if (errorCode) {
      if (errorCode === 404) {
        return <PageNotFound history={history} />;
      }

      if (errorCode === 500) {
        return <InternalError />;
      }
    }

    //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/678#note_332576
    const renderTermsOfServiceFlag =
      userConfig?.company?.companyType === CompanyTypes.EXTERNAL;

    return (
      <SettingsFormWrapper>
        <CollapseBox header={<Msg id="SETTINGS_PROFILE_AND_ACCOUNT_HEADER" />}>
          <Helmet>
            <title>{createPageTitle(intl, 'ACCOUNT_PAGE_TITLE')}</title>
          </Helmet>
          <FinalForm
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            render={({handleSubmit}) => (
              <Form onSubmit={handleSubmit}>
                <FormFieldsWrapper>
                  <Grid>
                    <Logo>
                      {/*TO-DO: Logo will be implemented later in another ticket
                        <Grid.Row>LOGO Space holder</Grid.Row>*/}
                    </Logo>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          data-spec="account-page-userId"
                          label={intl.formatMessage({id: 'USER_ID'})}
                          name="userId"
                          component={InputField}
                          disabled
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column width={8}>
                        <Field
                          translateError
                          data-spec="account-page-firstName"
                          label={intl.formatMessage({id: 'GENERAL_FIRST_NAME'})}
                          name="firstName"
                          component={InputField}
                          required
                          validate={required}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Field
                          translateError
                          data-spec="account-page-lastName"
                          label={intl.formatMessage({id: 'GENERAL_LAST_NAME'})}
                          name="lastName"
                          component={InputField}
                          required
                          validate={required}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column width={16}>
                        <Field
                          data-spec="account-page-telephone"
                          label={intl.formatMessage({id: 'GENERAL_TELEPHONE'})}
                          name="telephone"
                          component={InputField}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                      <Grid.Column width={4}>
                        <Field
                          translateError
                          data-spec="account-page-timeZone"
                          label={intl.formatMessage({id: 'GENERAL_TIME_ZONE'})}
                          name="timeZone"
                          component={DropdownField}
                          options={this.state.timeZones}
                          search
                          format={(value) => value}
                          required
                          upward={false}
                          validate={required}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column width={4}>
                        <Field
                          translateError
                          data-spec="account-page-locale"
                          label={intl.formatMessage({id: 'GENERAL_LOCALE'})}
                          name="locale"
                          options={localeOptions}
                          component={DropdownField}
                          format={(value) => value}
                          search
                          required
                          upward={false}
                          validate={required}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {renderTermsOfServiceFlag && (
                      <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                          <TermsOfServiceComponent
                            supplierTermOfServiceAccepted={get(
                              initialValues,
                              'supplierTermOfServiceAccepted',
                              false,
                            )}
                            {...this.props}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    {renderTermsOfServiceFlag && (
                      <Grid.Row columns={1}>
                        <Grid.Column width={16}>
                          <AnalyticsAcceptanceComponent
                            analyticsTrackingAccepted={get(
                              initialValues,
                              'analyticsTrackingAccepted',
                              false,
                            )}
                            {...this.props}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </FormFieldsWrapper>
                <SubmitButton
                  dataSpec="account-page-submitButton"
                  disabled={disableButton}
                  textId="SAVE_CHANGES"
                />
              </Form>
            )}
          />
        </CollapseBox>
      </SettingsFormWrapper>
    );
  }
}

AccountsPage.propTypes = {
  intl: intlShape.isRequired,
  userId: PropTypes.string.isRequired,
  history: historyShape.isRequired,
  userConfig: PropTypes.object.isRequired,
};

const AccountsPageWithRedux = connect(mapStateToProps)(AccountsPage);

export default injectIntl(AccountsPageWithRedux);

export const TermsOfServiceComponent = (props) => {
  const {supplierTermOfServiceAccepted, showTermsOfServiceLinkText = true} =
    props;
  return (
    <TermsOfServiceContainer>
      <Field
        translateError
        data-spec="account-page-terms-of-service-checkbox"
        name="supplierTermOfServiceAccepted"
        component={CustomCheckBox}
        disabled={supplierTermOfServiceAccepted}
        confirmText="ACCOUNTS_TERMS_OF_SERVICE_CONFIRM_TEXT"
        validate={required}
      />

      {showTermsOfServiceLinkText && (
        <div data-spec="account-page-terms-of-service-link-text">
          <Msg id="ACCOUNTS_TERMS_OF_SERVICE_LINK_TEXT">
            {(chunks) => htmlParse(chunks.join(''))}
          </Msg>
        </div>
      )}
    </TermsOfServiceContainer>
  );
};

export const AnalyticsAcceptanceComponent = (props) => {
  const {analyticsTrackingAccepted} = props;
  return (
    <AnalyticsContainer>
      <Field
        translateError
        data-spec="account-page-analytics-accepted-checkbox"
        name="analyticsTrackingAccepted"
        component={CustomCheckBox}
        disabled={analyticsTrackingAccepted}
        confirmText="ACCOUNTS_ANALYTICS_CONFIRM_TEXT"
      />
    </AnalyticsContainer>
  );
};

const CustomCheckBox = (props) => {
  const onChange = (event, {checked}) => {
    props.input.onChange(checked);
  };

  return (
    <div style={{marginBottom: '20px'}}>
      <CheckBoxContainer disabled={props?.disabled}>
        <Checkbox
          {...props}
          checked={props?.input?.value}
          onChange={onChange}
        />

        {props?.confirmText && (
          <div
            style={{marginLeft: '8px'}}
            data-spec={`${props['data-spec']}-confirm-text`}>
            <Msg id={props?.confirmText}>
              {(chunks) => htmlParse(chunks.join(''))}
            </Msg>
          </div>
        )}
      </CheckBoxContainer>
      {props?.meta?.touched && props?.meta?.error && (
        <ErrorLabel error={props?.meta?.error} />
      )}
    </div>
  );
};
